// extracted by mini-css-extract-plugin
export var featuredImageHeight = "40vh";
export var navbarHeight = "50rem";
export var maxFooterHeight = "64rem";
export var banner = "Banner-module--banner--1bKTq";
export var bannerContainer = "Banner-module--banner-container--2VgHd";
export var bannerHide = "Banner-module--banner-hide--3O0Qc";
export var bannerAppear = "Banner-module--banner-appear--2jAPc";
export var bannerHeader = "Banner-module--banner-header--2Ds8Q";
export var bannerText = "Banner-module--banner-text--3hG9s";
export var bannerButton = "Banner-module--banner-button--3yJb8";
export var bannerButtonContainer = "Banner-module--banner-button-container--2GbIr";
export var bounce = "Banner-module--bounce--2M18I";
export var shadowPulse = "Banner-module--shadow-pulse--1v8Nf";
export var bannerButtonIcon = "Banner-module--banner-button-icon--3Orbs";
export var minorPulse = "Banner-module--minor-pulse--CWlIR";